<template>
  <el-cascader
    clearable
    filterable
    placeholder="请选择"
    :props="contractProps"
    :options="options"
    v-model.array="category"
    @change="changeValue"
  >
  </el-cascader>
</template>

<script>
import { getCategoryList } from "../../../api/crm";
export default {
  name: "selectBusiness",
  components: {},
  props: {
    category: Array,
  },
  data() {
    return {
      options: [],
      contractProps: {
        expandTrigger: "hover",
      },
    };
  },
  mounted() {
    this.initBus();
    this.getCategory();
  },
  methods: {
    initBus() {
      this.$bus.off("businessTypeUpdate");
      this.$bus.on("businessTypeUpdate", (val) => {
        this.getCategory();
      });
    },
    getCategory() {
      if (this.options.length == 0) {
        getCategoryList({}).then((res) => {
          if (res.data.msg == "success") {
            let list = res.data.data.list;
            for (let i = 0; i < list.length; i++) {
              let children = [];
              if (list[i].children !== null && list[i].children.length > 0) {
                for (let j = 0; j < list[i].children.length; j++) {
                  children.push({
                    label: list[i].children[j].categoryName,
                    value: list[i].children[j].id.toString(),
                  });
                }
                this.options.push({
                  label: list[i].categoryType,
                  value: list[i].id.toString(),
                  children: children,
                });
              }
            }
          }
        });
      }
    },
    changeValue(val) {
      this.$emit("update:category", this.category);
      this.$emit("success", this.category);
    },
  },
};
</script>

<style scoped lang="less"></style>
