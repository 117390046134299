<template>
  <div>
    <div class="top_select">
      <el-radio-group v-model="listQuery.auditStatus" @change="getList">
        <el-radio-button :label="3">已审核</el-radio-button>
        <el-radio-button :label="1">未审核</el-radio-button>
        <el-radio-button :label="2">驳回</el-radio-button>
      </el-radio-group>
    </div>
    <div class="top_select">
      <div>
        <el-select
          v-model="listQuery.disposable"
          placeholder="请选择"
          @change="getList"
          size="small"
          style="width: 200px"
        >
          <el-option label="全部" :value="null" />
          <el-option label="周期性" value="0" />
          <el-option label="一次性" value="1" />
        </el-select>
        <el-select
          v-model="listQuery.contractState"
          placeholder="请选择"
          @change="handleChange"
          size="small"
          style="width: 200px"
        >
          
          <el-option label="正常合同" :value="1" />
          <el-option label="无效合同" :value="2" />
          <el-option label="欠费合同" :value="3" />
        </el-select>
        <el-checkbox
          v-model="listQuery.categoryTerm"
          v-if="listQuery.contractState == 1"
          label="待续费合同"
          true-label="1"
          :false-label="null"
          @change="getList"
        />
      </div>
      <div style="display: flex">
        <el-button
          type="primary"
          plain
          size="small"
          icon="FolderOpened"
          @click="daochu"
          :loading="daochuLoading"
          >导出</el-button
        >
        <el-dropdown style="padding-left: 5px">
          <el-button type="primary" size="small">
            新增<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="addContract()"
                >新增合同</el-dropdown-item
              >
              <el-dropdown-item @click="daoruFormVisible = true"
                >导入合同</el-dropdown-item
              >
              <el-dropdown-item @click="renewContract()"
                >续约合同</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="top_select">
      <div class="select_left">
        <div class="every_select">
          <el-input
            placeholder="请输入公司名称"
            v-model="listQuery.customerName"
            style="width: 200px; margin-right: 5px"
            size="small"
            @keyup.enter="getList"
          />
          <el-button
            type="primary"
            @click="getList()"
            size="small"
            icon="Search"
            >搜索</el-button
          >
        </div>
        <div style="font-size: 14px">
          合同结束时间:
          <el-date-picker
            v-model="listQuery.contractEndTime"
            type="year"
            placeholder="选择年份"
            size="small"
            @change="getList"
            value-format="YYYY"
            style="margin-right: 5px"
          />
          <el-date-picker
            v-model="listQuery.contractEndTime"
            type="month"
            placeholder="选择月份"
            size="small"
            @change="getList"
            value-format="YYYY-MM"
            style="margin-right: 5px"
          />
        </div>
        <div>
          <selectuser
            code="contractList"
            v-model:userId="listQuery.userId"
            @change="getList()"
          ></selectuser>
        </div>
      </div>
      <div class="count">金额合计数：{{ count }}</div>
    </div>
    <el-table
      :data="contractList"
      style="width: 98%; margin: 0 auto; margin-top: 10px"
      border
      :height="contentStyleObj"
      @selection-change="handleSelectContract"
      v-loading="listLoading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="center"
        fixed
        show-overflow-tooltip="false"
        width="300"
      />
      <el-table-column
        prop="contractNum"
        label="合同编号"
        width="180"
        align="center"
      />
      <el-table-column label="合同状态" align="center" width="100">
        <template #default="scope">
          <el-tag v-if="scope.row.contractState == 1">正常</el-tag>
          <el-tag
            class="ml-2"
            type="warning"
            v-if="scope.row.contractState == 2"
            >无效</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="categoryType"
        label="业务类型"
        width="180"
        align="center"
      />
      <el-table-column
        prop="categoryName"
        label="业务名称"
        width="180"
        align="center"
      />
      <el-table-column
        prop="contractStartTime"
        label="合同开始时间"
        width="130"
        align="center"
      />
      <el-table-column
        prop="contractEndTime"
        label="合同结束时间"
        width="130"
        align="center"
      />
      <el-table-column
        prop="contractRenewTime"
        label="下次续费日期"
        width="130"
        align="center"
      />
      <el-table-column
        prop="qfNumber"
        label="欠费金额"
        width="150"
        align="center"
        v-if="listQuery.contractState == 3"
      />
      <el-table-column
        prop="contractAmount"
        label="合同金额"
        width="150"
        align="center"
      />
      <el-table-column
        prop="contractActualAmount"
        label="实收金额"
        width="150"
        align="center"
      />

      <el-table-column
        prop="disCodes"
        label="业务负责人"
        width="150"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="remark"
        label="备注"
        width="150"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column label="操作" align="center" width="350" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            @click="saveEveryAllot(scope.row)"
            plain
            icon="Share"
            >分配</el-button
          >
          <el-button
            type="success"
            plain
            size="small"
            @click="updateContract(scope.row)"
            v-show="listQuery.auditStatus !== 1"
            icon="Edit"
            >编辑</el-button
          >
          <el-button
            type="warning"
            plain
            size="small"
            @click="updateContractState(scope.row)"
            icon="Warning"
            v-if="scope.row.contractState == 1"
            >改为无效</el-button
          >
          <el-button
            type="success"
            plain
            size="small"
            @click="reContractState(scope.row)"
            icon="Switch"
            v-else
            >还原为正常合同</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="delContract(scope.row)"
            plain
            icon="Delete"
            v-if="isAdmin == 1"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="top_select" style="margin-top: 8px">
      <div>
        <el-button
          type="primary"
          size="small"
          :disabled="this.sels.length === 0"
          @click="clickAllot"
          icon="Share"
          >批量分配</el-button
        >
        <el-button
          type="danger"
          size="small"
          :disabled="this.sels.length === 0"
          @click="delAll()"
          icon="Delete"
          v-if="isAdmin == 1"
          >批量删除</el-button
        >
      </div>
      <div class="pagination" style="margin-top: 0 !important">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList()"
        />
      </div>
    </div>
    <!-- 新增和修改合同 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="contractDialogVisible"
      :title="textMap[dialogStatus]"
      destroy-on-close
    >
      <el-form
        ref="contractRuleFormRef"
        :model="contractForm"
        :rules="rules"
        label-width="120px"
        status-icon
      >
        <el-form-item
          label="公司名称"
          prop="customerId"
          v-show="dialogStatus === 'create'"
          el-form-item--medium
        >
          <el-select
            placeholder="请选择"
            v-model="contractForm.customerId"
            filterable
          >
            <el-option
              v-for="item in customerOptions"
              :key="item.id"
              :label="item.customerName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同编码" prop="contractNum">
          <el-input v-model="contractForm.contractNum" />
        </el-form-item>
        <el-form-item label="业务名称" prop="category">
          <selectBusiness
            v-model:category="contractForm.category"
          ></selectBusiness>
        </el-form-item>
        <el-form-item label="合同开始时间" prop="contractStartTime">
          <el-date-picker
            v-model="contractForm.contractStartTime"
            type="date"
            label="请选择"
            placeholder="请选择"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="合同结束时间" prop="contractEndTime">
          <el-date-picker
            v-model="contractForm.contractEndTime"
            type="date"
            label="请选择"
            placeholder="请选择"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="续费日期">
          <el-date-picker
            v-model="contractForm.contractRenewTime"
            type="date"
            label="请选择"
            placeholder="请选择"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="合同金额" prop="contractAmount">
          <el-input v-model="contractForm.contractAmount" />
        </el-form-item>
        <el-form-item label="实收金额" prop="contractActualAmount">
          <el-input v-model="contractForm.contractActualAmount" />
        </el-form-item>
        <el-form-item label="联系人" prop="contactsName">
          <el-input v-model="contractForm.contactsName" />
        </el-form-item>
        <el-form-item label="联系电话" prop="contactsPhone">
          <el-input v-model="contractForm.contactsPhone" />
        </el-form-item>
        <el-form-item label="业务负责人" prop="userId">
          <business
            code="contractList"
            v-model:userId="contractForm.userId"
          ></business>
        </el-form-item>
        <el-form-item label="是否一次性" prop="disposable">
          <el-radio-group
            v-model="contractForm.disposable"
            @change="handleDisposable"
          >
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="contractForm.remark" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="contractDialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click="dialogStatus === 'create' ? createData() : updateData()"
          >
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量分配弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="allotFormVisible"
      title="分配"
      height="50vh"
    >
      <span>请选择跟进人：</span>
      <business code="contractList" v-model:userId="value"></business>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="allotFormVisible = false">取消</el-button>
          <el-button type="primary" @click="saveAllot()"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <renewContractDialog ref="renewContractDialog"></renewContractDialog>
    <!-- 导入模板 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="daoruFormVisible"
      title="导入"
      height="50vh"
    >
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input
            style="width: 60%; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <a href="javascript:;" @click="loadForm()">下载模板</a>
        <div style="text-align: right">
          <el-button size="small" type="primary" @click="uploadExcel"
            >上传</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getContractList,
  addContract,
  saveContractStatus,
  updateContract,
  getCustomerList,
  delAllContract,
  delContract,
  importContract,
  exportContract,
} from "../../../api/crm";
import selectBusiness from "../components/selectBusiness.vue";
import business from "../custom/components/business.vue";
import renewContractDialog from "./components/renewContractDialog.vue";
export default {
  name: "contractList",
  components: {
    selectBusiness,
    business,
    renewContractDialog,
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      isAdmin: this.$store.getters["user/user"].isAdmin,
      listLoading: false,
      count: 0,
      total: "",
      customerOptions: [],
      contractId: "",
      value: [],
      allotFormVisible: false,
      listQuery: {
        limit: 20,
        page: 1,
        customerId: null,
        auditStatus: 3,
        contractEndTime: null,
        businessName: null,
        disposable: null,
        contractState: 1,
        userId: null,
        categoryTerm: null,
        customerName: "",
      },
      date: "",
      id: "",
      contractList: [],
      sels: [],
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      daochuLoading: false,
      daoruFormVisible: false,
      rawFile: "",
      path: "",
      contractDialogVisible: false,
      contractForm: {
        contractId: null,
        contractNum: "",
        category: [],
        categoryType: "",
        categoryName: "",
        contractStartTime: "",
        contractEndTime: "",
        contractRenewTime: "",
        contractAmount: "",
        contractActualAmount: "",
        contactsName: "",
        contactsPhone: "",
        userId: [],
        disposable: false,
        remark: "",
        customerId: "",
        auditStatus: null,
      },
      rules: {
        customerId: [
          { required: true, message: "请选择公司名称", trigger: "change" },
        ],
        contractNum: [
          { required: true, message: "请输入合同编码", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
        contractStartTime: [
          { required: true, message: "请选择合同开始时间", trigger: "change" },
        ],
        contractEndTime: [
          { required: true, message: "请选择合同结束时间", trigger: "change" },
        ],
        contractAmount: [
          { required: true, message: "请输入合同金额", trigger: "blur" },
        ],
        contractActualAmount: [
          { required: true, message: "请输入实收金额", trigger: "blur" },
        ],
        contactsName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        userId: [
          {
            required: true,
            message: "请选择业务负责人",
            trigger: "change",
          },
        ],
        disposable: [
          { required: true, message: "请选择是或否", trigger: "change" },
        ],
        contactsPhone: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
      },
    };
  },
  created(){
    this.contentStyleObj= this.$getHeight(400)
  },
  mounted() {
    this.getList();
    this.initBus();
    this.getDay();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("commons/setParam", { customerId: null });
    next()
  },
  methods: {
    initBus() {
      this.$bus.off("contractUpdate");
      this.$bus.on("contractUpdate", (val) => {
        this.getList();
      });
      this.$bus.off("coopUpdate");
      this.$bus.on("coopUpdate", (val) => {
        this.getList();
        this.getCustomerList();
      });
    },
    destroyed() {
      console.log('销毁');
    },
    //查询合同
    getList() {
      this.listQuery.customerId = this.$store.getters[
        "commons/params"
      ].customerId;
      this.listLoading = true;
      getContractList(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.contractList = res.data.data.list;
          this.total = res.data.data.total;
          this.count = res.data.data.count;
        
        }
      });
    },
    getDay() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day <= 9 ? "0" + day : day;
      this.date = year + "-" + month + "-" + day;
    },
    //查询合作客户列表
    getCustomerList() {
      getCustomerList({ customerType: "2" }).then((res) => {
        if (res.data.msg == "success") {
          console.log(res);
          res.data.data.list.map((item) => {
            this.customerOptions.push({
              id: item.id,
              customerName: item.customerName,
            });
          });
        }
      });
    },
    //导入合同
    loadForm() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/合同列表导入.xlsx" +
          "?" +
          new Date().getTime()
      );
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importContract(param).then((res) => {
        if (res.data.msg == "success") {
          console.log(res);
          this.$qzfMessage("导入成功");
          this.getList();
          this.$bus.emit("contractUncheck");
          this.daoruFormVisible = false;
          this.path = "";
        }
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
    handleChange(val) {
      if (val != 1) {
        this.listQuery.categoryTerm = null;
      }
      this.getList();
    },
    //一次性变化设置
    handleDisposable(val) {
      if (val == 0) {
        this.contractForm.contractRenewTime = this.contractForm.contractEndTime;
      }
      if (val == 1) {
        this.contractForm.contractRenewTime = "";
      }
    },
    //续约合同
    renewContract() {
      this.$refs.renewContractDialog.getList();
    },
    //新增合同
    addContract() {
      this.resetForm();
      this.dialogStatus = "create";
      this.contractDialogVisible = true;
      if (this.customerOptions.length == 0) {
        this.getCustomerList();
      }
      this.contractForm.contractStartTime = this.date;
      this.$nextTick(() => {
        this.$refs["contractRuleFormRef"].clearValidate();
      });
    },
    //编辑合同
    updateContract(row) {
      this.contractForm = Object.assign({}, row);
      this.contractForm.category = [];
      this.contractForm.category[0] = this.contractForm.caType;
      this.contractForm.category[1] = this.contractForm.caName;
      this.dialogStatus = "update";
      this.contractDialogVisible = true;
      this.$nextTick(() => {
        this.$refs["contractRuleFormRef"].clearValidate();
      });
    },
    createData() {
      this.$refs["contractRuleFormRef"].validate((valid) => {
        if (valid) {
          if (
            this.contractForm.contractAmount == "0" ||
            this.contractForm.contractActualAmount == "0"
          ) {
            this.$qzfMessage("请填写正确金额", 1);
            return;
          }
          this.contractForm.auditStatus = 1;
          this.contractForm.contractAmount =
            (this.contractForm.contractAmount * 1).toFixed(2) * 1;
          this.contractForm.contractActualAmount =
            (this.contractForm.contractActualAmount * 1).toFixed(2) * 1;
          this.contractForm.categoryType = this.contractForm.category[0];
          this.contractForm.categoryName = this.contractForm.category[1];
          addContract(this.contractForm).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.contractDialogVisible = false;
              this.$qzfMessage("创建成功");
              this.$bus.emit("contractUncheck");
              this.options = [];
            }
          });
        }
      });
    },
    updateData() {
      this.$refs["contractRuleFormRef"].validate((valid) => {
        if (valid) {
          this.contractForm.auditStatus = 1;
          this.contractForm.contractAmount =
            (this.contractForm.contractAmount * 1).toFixed(2) * 1;
          this.contractForm.contractActualAmount =
            (this.contractForm.contractActualAmount * 1).toFixed(2) * 1;
          this.contractForm.categoryType = this.contractForm.category[0];
          this.contractForm.categoryName = this.contractForm.category[1];
          updateContract(this.contractForm).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.contractDialogVisible = false;
              this.$bus.emit("contractUncheck");
              this.options = [];
              this.$qzfMessage("修改成功");
            }
          });
        }
      });
    },
    resetForm() {
      this.contractForm = {
        contractId: null,
        contractNum: "",
        category: [],
        categoryType: "",
        categoryName: "",
        contractStartTime: "",
        contractEndTime: "",
        contractRenewTime: "",
        contractAmount: "",
        contractActualAmount: "",
        contactsName: "",
        contactsPhone: "",
        userId: [],
        disposable: false,
        remark: "",
        customerId: "",
        auditStatus: null,
      };
    },
    handleSelectContract(sels) {
      this.sels = sels;
    },
    //单独分配
    saveEveryAllot(row) {
      this.value = row.userId;
      this.allotFormVisible = true;
      this.contractId = row.id;
    },
    clickAllot() {
      this.contractId = "";
      this.value = [];
      this.allotFormVisible = true;
    },
    //批量分配跟进人
    saveAllot() {
      if (this.contractId == "") {
        if (this.value.length == 0) {
          this.$qzfMessage("请选择跟进人", 1);
          return;
        }
        let selects = [];
        this.sels.map((item) => {
          selects.push({
            id: item.id,
            userId: this.value,
          });
        });
        saveContractStatus(selects).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("分配成功");
            this.allotFormVisible = false;
            this.value = [];
            this.getList();
            this.$bus.emit("contractUncheck");
          }
        });
      } else {
        updateContract({
          id: this.contractId,
          userId: this.value,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("修改成功");
            this.allotFormVisible = false;
            this.contractId = "";
            this.value = [];
            this.getList();
            this.$bus.emit("contractUncheck");
          }
        });
      }
    },
    //单独删除
    delContract(row) {
      this.$confirm("确认删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delContract({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("contractUncheck");
            }
          });
        })
        .catch(() => {});
    },
    //批量删除
    delAll() {
      this.$confirm("确认删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          delAllContract(ids).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("contractUncheck");
            }
          });
        })
        .catch(() => {});
    },
    //解约
    updateContractState(row) {
      this.$confirm("确认将合同修改为无效吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateContract({ id: row.id, contractState: 2 }).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.$bus.emit("contractUncheck");
              this.$qzfMessage("修改成功");
            }
          });
        })
        .catch(() => {});
    },
    //还原为正常合同
    reContractState(row) {
      this.$confirm("确认还原为正常合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateContract({ id: row.id, contractState: 1 }).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.$bus.emit("contractUncheck");
              this.$qzfMessage("修改成功");
            }
          });
        })
        .catch(() => {});
    },
    //导出
    daochu() {
      this.daochuLoading = true;
      let list = [];
      let that = this;
      if (that.sels.length == 0) {
        list = that.contractList;
      } else {
        list = that.sels;
      }
      exportContract(list).then((res) => {
        this.daochuLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  width: 98%;
  display: flex;
  margin: 0 auto;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;
}
.select_left {
  display: flex;
}
.every_select {
  margin-right: 5px;
}
.el-form-item {
  width: 46% !important;
  margin-bottom: 22px !important;
}
.count {
  font-size: 20px;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
}
:deep(.el-table__cell) {
  padding: 3px 0 !important;
}
.el-select {
  margin: 0 5px 0 0 !important;
}
:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: #3c6ff2;
  border-color: #3c6ff2;
}
</style>
